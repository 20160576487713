import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Boka from "../views/Boka.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    alias: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }, {
    path: "/boka",
    name: "boka",
    component: Boka
  }
]

const scrollBehavior = (to: any, from: any, savedPosition: any) => {
  if (to.hash) {
    const element = document.getElementById(to.hash.replace(/#/, ''))
    if (element && element.scrollIntoView) {
      element.scrollIntoView({ block: 'end', behavior: 'smooth' })
      return null;
    }
    return { el: to.hash };
  }
  else if (savedPosition) {
    return savedPosition
  }
  return { top: 0 }
}


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: scrollBehavior
})

export default router
