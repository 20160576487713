
import { defineComponent, onMounted, onUnmounted } from "vue";

let part = 0;
let part_index = 0;
let interval_val = 0;
let element = document.querySelector("#text")!;
let cursor = document.querySelector("#cursor")!;
let words: string[] = [];
let cursorStyle = "none";

const typewriter = () => {
  element = document.querySelector("#text")!;
  cursor = document.querySelector("#cursor")!;

  var text = words[part].substring(0, part_index + 1);
  element.innerHTML = text;
  part_index++;

  if (text === words[part]) {
    // Hide the cursor
    cursorStyle = "none";
    clearInterval(interval_val);
    setTimeout(function () {
      interval_val = setInterval(erase, 100, words);
    }, 1000);
  }
};

const erase = () => {
  var text = words[part].substring(0, part_index - 1);
  element.innerHTML = text;
  part_index--;

  // If sentence has been deleted then start to display the next sentence
  if (text === "") {
    clearInterval(interval_val);

    // If current sentence was last then display the first one, else move to the next
    if (part == words.length - 1) part = 0;
    else part++;

    part_index = 0;

    // Start to display the next sentence after some time
    setTimeout(function () {
      cursorStyle = "inline-block";
      
      interval_val = setInterval(typewriter, 150);
    }, 200);
  }
};

const start = () => {
  clearInterval(interval_val);
  part = 0;
  part_index = 0;
  interval_val = 0;
  element = document.querySelector("#text")!;
  cursor = document.querySelector("#cursor")!;
  interval_val = setInterval(typewriter, 100);
};

export default defineComponent({
  name: "Home",
  components: {},
  props: {
    text: Array,
  },
  setup(props) {
    words = props.text as string[];
    onMounted(start);
    onUnmounted(() => clearInterval(interval_val));
  }
});
